/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription sellerAccountSubscribeSubscription {
    sellerAccountSubscribe {
      id
      ...SellerAccountBasicInfoForm_sellerAccount
      ...SellerAccountCompanyForm_sellerAccount
      ...SellerAccountPersonForm_sellerAccount
      ...SellerAccountPayoutMethodForm_sellerAccount
    }
  }
`;

const sellerAccountSubscribe = () =>
  requestSubscription({
    subscription,
  });

export default sellerAccountSubscribe;
