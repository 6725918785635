import React, { useState, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { createRefetchContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { libphonenumberHelpers, asYouType } from '@pluralcom/plural-js-utils';
import { InputTags, Divider } from '@pluralcom/blueprint';
import { InputTagsRefHandle } from '@pluralcom/blueprint/dist/react/molecules/InputTags';

import { onKeyEnterExec } from '@pluralcom/plural-web-utils';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import validator from 'validator';

import { inputsMaxLengths, errorTexts } from '../../assets/data';
import { uiDomHelpers, validation } from '../../utils';
import { useGeolocation } from '../../hooks';

import { CorrectKnowsPhoneMutation } from '../../graphql/mutations';
import DialogInputTelephoneConfirm from '../DialogInputTelephoneConfirm/DialogInputTelephoneConfirm';

import { InputUsersList } from './components/index';

import styles from './InputUsers.module.scss';

import { InputUsers_viewer$data } from './__generated__/InputUsers_viewer.graphql';
import CustomTextListItem from './components/CustomTextListItem/CustomTextListItem';
import ConnectSection from '../ConnectSection/ConnectSection';

const REFETCH_DELAY = 150;

interface Props {
  /** Function to be called on change of Tag */
  onChangeTags?: Function;
  /** custom className */
  className?: string;
  /** Dropdown */
  dropdown?: boolean;
  /** Viewer */
  viewer: InputUsers_viewer$data;
  // @todo rename to onSelect
  /** Function to be called on select of person */
  onSelectPerson: Function;
  /** Function to be called on change of input */
  onChangeInput?: Function;
  /** Function to be called on outside click */
  onClickOutside?: Function;
  /** Auto focus */
  autoFocus?: boolean;
  /** inputTagsProps */
  inputTagsProps: Omit<
    React.ComponentProps<typeof InputTags>,
    'value' | 'onChange' | 'onAdd' | 'onRemove'
  >;
  /** relay */
  relay: {
    /** refetch */
    refetch: Function;
  };
  /** hasModal */
  hasModal?: boolean;
  /** inputTags uiType */
  uiType?: 'form' | 'raw';
  /** tagItem */
  tagItem?: [];
}

/**
 *
 * FIGMA v0.0.1
 * InputUsers component to render a list of users  and show connectSection
 */
const InputUsers = ({
  onChangeTags,
  className,
  dropdown,
  viewer,
  onSelectPerson,
  onChangeInput,
  onClickOutside,
  autoFocus,
  inputTagsProps,
  relay,
  uiType = 'form',
  hasModal,
  tagItem,
}: Props) => {
  const inputTagsRef = useRef<InputTagsRefHandle>();
  const inputTagsInputRef = useRef<HTMLInputElement>();
  const [position] = useGeolocation();
  const { coords } = position || {};

  const [searchTerm, setSearchTerm] = useState(
    inputTagsProps?.inputProps?.value || '',
  );
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showDialogInputTelephone, setShowDialogInputTelephone] = useState(
    false,
  );
  const [dialogProps, setDialogProps] = useState<
    React.ComponentProps<typeof DialogInputTelephoneConfirm>
  >({ value: '' });
  const [isLoadingCorrectKnowsPhone, setIsLoadingCorrectKnowsPhone] = useState(
    false,
  );
  const [isConnectSectionModalOpen, setIsConnectSectionModalOpen] = useState(
    false,
  );

  /** Whether the search term is valid for direct use; ie: can be used directly as is since it's a valid email or phone number */
  const isValidSearchTermForDirectUse = Boolean(
    searchTerm?.trim().length &&
      (validator.isEmail(searchTerm) ||
        libphonenumberHelpers.isPossiblePhoneStr(searchTerm)),
  );

  const debouncedRefetch = useMemo(
    () =>
      debounce((debounceQueryParams) => {
        /* Don't show the loading spinner when the user backspaces to empty string */
        setIsLoading(true);
        const vars: {
          /** Search term */
          searchTerm: string;
          /** Should fetch */
          _shouldFetch: boolean;
          /** Latitude */
          latitude?: number;
          /** Longitude */
          longitude?: number;
        } = {
          searchTerm: debounceQueryParams.searchTerm,
          _shouldFetch: true,
        };
        if (
          isNumber(debounceQueryParams?.coords?.latitude) &&
          isNumber(debounceQueryParams?.coords?.longitude)
        ) {
          vars.latitude = debounceQueryParams.coords.latitude;
          vars.longitude = debounceQueryParams.coords.longitude;
        }
        relay.refetch(
          (existingVars) => ({
            // exisitng
            ...existingVars,
            // new
            ...vars,
          }),
          undefined,
          () => setIsLoading(false),
        );
      }, REFETCH_DELAY),
    [relay],
  );

  /** Set tags from tagItem */
  useEffect(() => {
    if (tagItem) {
      setTags(tagItem);
    }
  }, [tagItem]);

  /** refetch effect  */
  useEffect(() => {
    debouncedRefetch({ searchTerm, coords });
  }, [searchTerm, coords, debouncedRefetch]);

  const _getCustomInputTagType = (tag): 'phonenumber' | 'email' | undefined => {
    const searchTermToCheck = tag.searchTerm || tag.name || '';
    if (
      libphonenumberHelpers.isValidIntlOrUSNumber(
        searchTermToCheck?.split?.(' ')?.join?.(''),
      )
    ) {
      return 'phonenumber';
    }
    if (validation.isValidEmail(searchTermToCheck)) {
      return 'email';
    }
    return undefined;
  };

  const _createCustomTag = (rawTag) => {
    const tagType = _getCustomInputTagType(rawTag);
    if (tagType === 'phonenumber') {
      const phone = libphonenumberHelpers.formatNumber(
        (rawTag.searchTerm || rawTag.name)?.split?.(' ')?.join?.(''),
      );
      return {
        phone,
        name: phone,
        ...rawTag,
        tagType,
      };
    }
    if (tagType === 'email') {
      const email = rawTag.searchTerm || rawTag.name;
      return {
        email,
        name: email,
        ...rawTag,
        tagType,
      };
    }

    return {
      ...rawTag,
      tagType,
      error: errorTexts.invalidEmailOrPhoneNumber,
    };
  };

  /** Updates tags with new custom tag during on change */
  const _handleChangeTags = (newTags) =>
    // tags
    {
      setTags(newTags);
      if (onChangeTags) {
        onChangeTags(newTags);
      }
      setSearchTerm('');
    };

  /** Updates tags with a selected tag from menu */
  const _handleSelectPerson = async (person) => {
    /** Check for plural user ID existance (real user) */
    const isPluralUser = Boolean(person?.id);

    /** Check for email */
    let emailValue;
    const mainEmailValToParse = (person.searchTerm || person.email || '')
      .toLowerCase()
      .trim();
    if (mainEmailValToParse && validation.isValidEmail(mainEmailValToParse)) {
      emailValue = mainEmailValToParse;
    }

    /** Check for phone */
    let phoneValue;
    const mainPhoneValToParse = person.searchTerm || person.phone || '';
    const isPossiblePhoneValue =
      mainPhoneValToParse &&
      libphonenumberHelpers.isPossiblePhoneStr(mainPhoneValToParse);
    let parsedPhoneObj = {
      phone: null,
      e164: null,
      parsedWithDefaultCountry: {
        e164: null,
      },
    };
    if (isPossiblePhoneValue) {
      parsedPhoneObj =
        libphonenumberHelpers.attemptParsePhoneNumber(
          mainPhoneValToParse.trim(),
        ) || {};
      if (parsedPhoneObj?.phone) {
        phoneValue = parsedPhoneObj?.e164;
      }
    }

    /** Passed a completely invlidphone as searchterm */
    if (
      person.searchTerm &&
      libphonenumberHelpers.isPossiblePhoneStr(person.searchTerm) &&
      !parsedPhoneObj.phone
    ) {
      setShowDialogInputTelephone(true);
      setDialogProps({ value: person.searchTerm });
      return;
    }

    if (!(isPluralUser || emailValue || phoneValue)) {
      if (
        person.phone &&
        libphonenumberHelpers.isPossiblePhoneStr(person.phone)
      ) {
        if (
          parsedPhoneObj &&
          parsedPhoneObj.parsedWithDefaultCountry &&
          parsedPhoneObj.parsedWithDefaultCountry.e164
        ) {
          /** possible phone parsed with default country */
          setShowDialogInputTelephone(true);
          setDialogProps({
            value: parsedPhoneObj.parsedWithDefaultCountry.e164,
            onSuccess: () => {
              // eslint-disable-next-line no-use-before-define
              _handleDialogInputTelephoneSuccess({
                current_value: person.phone,
              });
            },
          });
        } else {
          /** possible phone non parsed */
          setShowDialogInputTelephone(true);
          setDialogProps({
            value: person.phone,
            onSuccess: () => {
              // eslint-disable-next-line no-use-before-define
              _handleDialogInputTelephoneSuccess({
                current_value: person.phone,
              });
            },
          });
        }
      }
      return;
    }
    setSearchTerm('');

    /** Handle success */
    /** Determine thread query input */
    let personInputData;
    /** Check for plural user ID existance (real user OR ghost user) */
    if (isPluralUser) {
      personInputData = {
        userId: person.id,
        ...person,
      };
    } else if (emailValue) {
      personInputData = { email: emailValue };
    } else {
      /** phone */
      personInputData = { phone: phoneValue };
    }

    // Call onSelectPerson with valid data
    if (onSelectPerson) {
      onSelectPerson(personInputData);
    }

    // Set tags and call onChangeTags with valid data
    const newTags = tags.concat(
      isPluralUser
        ? {
            ...person,
            tagType: 'user',
          }
        : _createCustomTag(person),
    );
    setTags(newTags);
    if (onChangeTags) {
      onChangeTags(newTags);
    }

    // close dropdown
    requestAnimationFrame(() => {
      inputTagsRef.current?.setIsOpenDropdown?.(false);
    });
  };

  const _handleDialogInputTelephoneSuccess = ({
    current_value = '',
  } = {}) => async ({ e164 }) => {
    setIsLoadingCorrectKnowsPhone(true);
    let newUserId;
    if (current_value) {
      const {
        correctKnowsPhone: correctKnowsPhoneRes,
      } = await CorrectKnowsPhoneMutation(
        {
          current_value,
          new_value: e164,
        },
        {
          // @ts-ignore
          parentID: viewer?.search?.__id,
        },
      );
      newUserId = correctKnowsPhoneRes?.userEdge?.node?.id;
    }
    setIsLoadingCorrectKnowsPhone(true);
    setDialogProps({ value: '' });
    _handleSelectPerson(
      newUserId
        ? {
            user: { id: newUserId },
          }
        : {
            searchTerm: e164,
          },
    );
    setShowDialogInputTelephone(false);
  };

  const _handleChangeInput = (_inputValue) => {
    const synthesizedValue = asYouType('email_phone', _inputValue);
    const caretPos = inputTagsInputRef.current
      ? inputTagsInputRef?.current?.selectionStart
      : _inputValue.length;
    setSearchTerm(synthesizedValue);
    if (inputTagsInputRef.current) {
      uiDomHelpers.setCaretPosition(
        inputTagsInputRef.current,
        caretPos > synthesizedValue.length ? synthesizedValue.length : caretPos,
      );
    }
    if (onChangeInput) {
      const isValidEmail = validation.isValidEmail(synthesizedValue.trim());
      const isValidPhone =
        !isValidEmail &&
        libphonenumberHelpers.attemptParsePhoneNumber(synthesizedValue.trim())
          ?.phone;
      onChangeInput({
        value: synthesizedValue,
        isValid: (synthesizedValue.trim() && isValidEmail) || isValidPhone,
        isValidEmail,
        isValidPhone,
      });
    }
  };

  const menuChild = (props) => (
    <div
      {...props}
      className={classNames([
        styles.menu,
        { [styles.dropdown]: dropdown },
        { [styles['dropdown--raw']]: uiType === 'raw' },
        props.className,
      ])}
    >
      <InputUsersList
        ListHeaderComponent={
          <>
            <ConnectSection
              viewer={viewer}
              setIsConnectSectionModalOpen={setIsConnectSectionModalOpen}
            />
            <div>
              <Divider size="0.5rem" gx="0" gy="0" />
            </div>
            {isValidSearchTermForDirectUse ? (
              <CustomTextListItem
                emailOrPhone={searchTerm}
                {...{ onClick: () => _handleSelectPerson({ searchTerm }) }}
              />
            ) : null}
          </>
        }
        className={styles['input-list-container']}
        search={viewer?.search}
        searchTerm={searchTerm}
        userLocation={coords}
        onClickItem={_handleSelectPerson}
        isLoading={isLoading}
        lastNetworkSyncedTimestamp={
          viewer?.profile?.last_network_synced_timestamp
        }
      />
    </div>
  );
  return (
    <div className={classNames([styles.container, className])}>
      <InputTags
        uiType={uiType}
        maxLength={inputsMaxLengths.peopleSelectorTags}
        addKeys={[]}
        tagLabelField="name"
        {...inputTagsProps}
        // @ts-ignore
        ref={inputTagsRef}
        autoFocus={autoFocus}
        value={tags}
        onChange={_handleChangeTags}
        tagProps={{
          ...inputTagsProps?.tagProps,
          withButtonClose: true,
        }}
        inputProps={{
          ref: inputTagsInputRef,
          value: searchTerm,
          spellcheck: false,
          type: 'search',
          placeholder: 'Type name, email, or phone',
          maxLength: inputsMaxLengths.peopleSelectorTag,
          className: styles['input-tags'],
          ...inputTagsProps?.inputProps,
          onKeyUp: onKeyEnterExec(() => {
            _handleSelectPerson({ name: searchTerm });
          }),
          onChange: (e) => _handleChangeInput(e.target.value),
        }}
        renderDropdown={dropdown ? menuChild : null}
        onClickOutside={
          showDialogInputTelephone
            ? undefined
            : !isConnectSectionModalOpen && onClickOutside
        }
        inputTextProps={{
          leftIconProps: null,
          ...inputTagsProps?.inputTextProps,
        }}
        dropdownContainerClassName={styles['dropdown-container']}
      >
        <DialogInputTelephoneConfirm
          dialogProps={{
            isOpen: showDialogInputTelephone,
            toggle: () => {
              setShowDialogInputTelephone(false);
              setDialogProps({ value: '' });
            },
          }}
          onSuccess={_handleDialogInputTelephoneSuccess()}
          primaryBtnProps={{
            loading: isLoadingCorrectKnowsPhone,
          }}
          {...dialogProps}
        />
      </InputTags>
      {!dropdown && !hasModal && menuChild({})}
    </div>
  );
};

export { InputUsers as PureInputUsers };

export default createRefetchContainer(
  InputUsers,
  {
    viewer: graphql`
      fragment InputUsers_viewer on ViewerType
      @argumentDefinitions(
        searchTerm: { type: "String", defaultValue: "" }
        latitude: { type: "Float" }
        longitude: { type: "Float" }
      ) {
        profile {
          id
          last_network_synced_timestamp
        }
        ...ModalConnectSection_viewer
        search(term: $searchTerm, longitude: $longitude, latitude: $latitude) {
          ...InputUsersList_search
        }
        ...ConnectSection_viewer
          @arguments(latitude: $latitude, longitude: $longitude)
      }
    `,
  },
  graphql`
    query InputUsers_Refetch_Query(
      $searchTerm: String
      $latitude: Float
      $longitude: Float
    ) {
      viewer {
        ...InputUsers_viewer
          @arguments(
            searchTerm: $searchTerm
            latitude: $latitude
            longitude: $longitude
          )
      }
    }
  `,
);
