/**
 * @generated SignedSource<<d9a3ee27815d23984ec1e79a8de98451>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StripeCurrencyEnumType = "ARS" | "AUD" | "BGN" | "CAD" | "CZK" | "DKK" | "EGP" | "EUR" | "HUF" | "MXN" | "NZD" | "PLN" | "RON" | "SAR" | "SEK" | "USD" | "%future added value";
export type AddPayoutInput = {
  bank_account_token?: string | null;
  clientMutationId?: string | null;
  payout_method_id?: string | null;
  seller_account_id?: string | null;
};
export type AddPayoutMethodMutation$variables = {
  input: AddPayoutInput;
};
export type AddPayoutMethodMutation$data = {
  readonly addPayout: {
    readonly bank_account: {
      readonly account_holder_name: string | null;
      readonly country: string | null;
      readonly currency: StripeCurrencyEnumType | null;
      readonly id: string;
      readonly last4: string | null;
      readonly routing_number: string | null;
      readonly status: string | null;
    } | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly seller_account: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountPayoutMethodForm_sellerAccount">;
    } | null;
    readonly user: {
      readonly id: string;
      readonly settings: {
        readonly has_valid_payout_method: boolean | null;
        readonly id: string;
      } | null;
    } | null;
  } | null;
};
export type AddPayoutMethodMutation = {
  response: AddPayoutMethodMutation$data;
  variables: AddPayoutMethodMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_holder_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "routing_number",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccount",
  "kind": "LinkedField",
  "name": "bank_account",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Settings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_valid_payout_method",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddPayoutMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddPayoutPayload",
        "kind": "LinkedField",
        "name": "addPayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SellerAccount",
            "kind": "LinkedField",
            "name": "seller_account",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerAccountPayoutMethodForm_sellerAccount"
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddPayoutMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddPayoutPayload",
        "kind": "LinkedField",
        "name": "addPayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SellerAccount",
            "kind": "LinkedField",
            "name": "seller_account",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StripeAccountRequirements",
                "kind": "LinkedField",
                "name": "requirements",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currently_due",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StripeAccountRequirementsError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requirement",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eventually_due",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "past_due",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pending_verification",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "BankAccountConnection",
                "kind": "LinkedField",
                "name": "payout_methods",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankAccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BankAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payout_methods(first:1)"
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "SellerAccountPayoutMethodForm_payout_methods",
                "kind": "LinkedHandle",
                "name": "payout_methods"
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02974fe3732bf91e3071dfce03e2f40f",
    "id": null,
    "metadata": {},
    "name": "AddPayoutMethodMutation",
    "operationKind": "mutation",
    "text": "mutation AddPayoutMethodMutation(\n  $input: AddPayoutInput!\n) {\n  addPayout(input: $input) {\n    error {\n      message\n      id\n    }\n    bank_account {\n      id\n      account_holder_name\n      routing_number\n      last4\n      currency\n      country\n      status\n    }\n    seller_account {\n      id\n      ...SellerAccountPayoutMethodForm_sellerAccount\n    }\n    user {\n      id\n      settings {\n        id\n        has_valid_payout_method\n      }\n    }\n  }\n}\n\nfragment SellerAccountPayoutMethodForm_sellerAccount on SellerAccount {\n  id\n  requirements {\n    id\n    currently_due\n    errors {\n      code\n      reason\n      requirement\n    }\n    eventually_due\n    past_due\n    pending_verification\n  }\n  payout_methods(first: 1) {\n    edges {\n      node {\n        id\n        account_holder_name\n        routing_number\n        last4\n        currency\n        country\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "635df03b03a4af97d911ea461a52ac10";

export default node;
