/* @flow */
import urlJoin from 'url-join';

import getWebUrl from '../getUrlHelpers/getWebUrl';

/**
 * This method will return skill link
 */
const getLinkSkill = (
  skill: {
    id: string,
    title: string,
    media?: {
      edges?: Array<{
        node?: {
          image?: {
            medium?: string,
          },
        },
      }>,
    },
    user: {
      id: string,
      first_name: string,
      last_name: string,
      avatar?: { medium?: string },
    },
  },
  {
    disableErrorMiddleware,
  }: {
    disableErrorMiddleware?: boolean,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const { id: skill_id } = skill;
    const url = urlJoin(getWebUrl(), `skills/${skill_id}`);
    return resolve(url);
  });

/**
 * This method will return profile link
 */
const getLinkProfile = (
  user: {
    id: string,
    first_name: string,
    last_name: string,
    avatar?: { medium?: string },
  },
  {
    disableErrorMiddleware,
  }: {
    disableErrorMiddleware?: boolean,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const desktopUrl = urlJoin(getWebUrl(), user.username || user.id);
    return resolve(desktopUrl);
  });

/**
 * This method will return skill link
 */
const getLinkSkillOffer = (
  skill: {
    id: string,
    title: string,
    media?: {
      edges?: Array<{
        node?: {
          image?: {
            medium?: string,
          },
        },
      }>,
    },
    user: {
      id: string,
      first_name: string,
      last_name: string,
      avatar?: { medium?: string },
    },
  },
  offer: {
    id: string,
  },
  {
    disableErrorMiddleware,
  }: {
    disableErrorMiddleware?: boolean,
  } = {},
) =>
  new Promise((resolve, reject) => {
    const { id: skill_id } = skill;
    const { id: offer_id } = offer;

    const desktopUrl = urlJoin(
      getWebUrl(),
      `skills/${skill_id}/offers/${offer_id}`,
    );
    return resolve(desktopUrl);
  });

/**
 * This method will return base link
 */
const getLinkBasic = ({ customMetadata, ...rest } = {}) =>
  new Promise((resolve, reject) => {
    const desktopUrl = rest.$desktop_url || window.location.href;
    return resolve(desktopUrl);
  });

export { getLinkBasic, getLinkSkill, getLinkProfile, getLinkSkillOffer };
