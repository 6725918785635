import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

// Query
const mutation = graphql`
  mutation AddPayoutMethodMutation($input: AddPayoutInput!) {
    addPayout(input: $input) {
      error {
        message
      }
      # update existing payout method in case of edit
      bank_account {
        id
        account_holder_name
        routing_number
        last4
        currency
        country
        status
      }
      # create new payout method in case of add
      # bank_account_edge {
      #   node {
      #     id
      #     account_holder_name
      #     routing_number
      #     last4
      #     currency
      #     country
      #     status
      #   }
      # }
      seller_account {
        id
        ...SellerAccountPayoutMethodForm_sellerAccount
      }
      user {
        id
        settings {
          id
          has_valid_payout_method
        }
      }
    }
  }
`;

interface AddPayoutMethodInput {
  /** bank account token */
  bank_account_token: string;
  /** seller account id */
  seller_account_id?: string;
  /** payout method id */
  payout_method_id?: string;
}

export default (
  {
    bank_account_token,
    seller_account_id,
    payout_method_id,
  }: AddPayoutMethodInput,
  {
    parentID,
  }: {
    /** parent node ID */
    parentID?: string;
  } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    // Variables for query
    const variables = {
      input: {
        seller_account_id,
        payout_method_id,
        bank_account_token,
      },
    };

    // config
    const configs = [
      {
        type: 'RANGE_ADD',
        parentID,
        connectionInfo: [
          {
            key: 'SellerAccountPayoutMethodForm_payout_methods',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'bank_account_edge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
