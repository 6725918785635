/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import omit from 'lodash/omit';

import { commitMutation } from '../..';
import { localStorageHelpers } from '../../../utils';

const mutation = graphql`
  mutation EditProfileMutation($input: EditProfileInput!) {
    editProfile(input: $input) {
      profile {
        id
        username
        first_name
        last_name
        email
        phone
        birthdate
        bio
        website
        gender
        profile_color
        avatar {
          id
          thumbnail
          smallThumbnail
        }
        location {
          id
          latitude
          longitude
          name
          is_anonymized
        }
        socialProfiles {
          id
          instagram
          github
          dribbble
          twitter
          linkedin
          medium
          youtube
          vimeo
          flickr
          behance
          snapchat
          pinterest
          quora
          tumblr
          stackoverflow
          patreon
          buymeacoffee
          tiktok
          substack
        }
      }
      error {
        message
      }
    }
  }
`;

export default ({
  id,
  first_name,
  last_name,
  username,
  location,
  bio,
  website,
  birthdate,
  instagram,
  github,
  dribbble,
  twitter,
  linkedin,
  medium,
  youtube,
  vimeo,
  flickr,
  behance,
  snapchat,
  pinterest,
  quora,
  tumblr,
  stackoverflow,
  gender,
  patreon,
  buymeacoffee,
  tiktok,
  substack,
}: {
  id: string,
  first_name?: string,
  last_name?: string,
  username?: string,
  location?: {
    longitude: number,
    latitude: number,
    is_anonymized: boolean,
  },
  bio?: string,
  website?: string,
  birthdate?: string,
  instagram?: string,
  github?: string,
  dribbble?: string,
  twitter?: string,
  linkedin?: string,
  medium?: string,
  youtube?: string,
  vimeo?: string,
  flickr?: string,
  behance?: string,
  snapchat?: string,
  pinterest?: string,
  quora?: string,
  tumblr?: string,
  stackoverflow?: string,
  gender?: string,
  patreon?: string,
  buymeacoffee?: string,
  tiktok?: string,
  substack?: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        first_name,
        last_name,
        username,
        bio,
        website,
        birthdate,
        location,
        gender,
        socialProfiles: {
          instagram,
          github,
          dribbble,
          twitter,
          linkedin,
          medium,
          youtube,
          vimeo,
          flickr,
          behance,
          snapchat,
          pinterest,
          quora,
          tumblr,
          stackoverflow,
          patreon,
          buymeacoffee,
          tiktok,
          substack,
        },
      },
    };

    const optimisticResponse = {
      editProfile: {
        error: null,
        profile: {
          id,
          first_name,
          last_name,
          username,
          bio,
          website,
          birthdate,
          location,
          gender,
          socialProfiles: {
            instagram,
            github,
            dribbble,
            twitter,
            linkedin,
            medium,
            youtube,
            vimeo,
            flickr,
            behance,
            snapchat,
            pinterest,
            quora,
            tumblr,
            stackoverflow,
            patreon,
            buymeacoffee,
            tiktok,
            substack,
          },
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: (data, errors) => {
        if (data?.editProfile?.profile) {
          localStorageHelpers.setUser((user) => ({
            ...user,
            ...omit(data.editProfile.profile, ['socialProfiles']),
          }));
        }
        resolve(data, errors);
      },
      onError: reject,
    });
  });
