/* @flow */
import React, { useState } from 'react';
import { analyticsHelpers } from '@pluralcom/plural-js-utils';

import { mixpanelHelpers } from '../../../utils';
import MapboxMap from '../MapboxMap/MapboxMap';
import MapMarker from '../MapMarker/MapMarker';
import {
  UserInfoWindow,
  SkillInfoWindow,
  MapMarkerUserSearchPublic,
  MapLegend,
} from './components';
import MapMarkerDot from './components/MapMarkerDot/MapMarkerDot';
import ModalSearchedSkill from '../../ModalSearchedSkill/ModalSearchedSkill';
import styleUserInfoWindow from './components/UserInfoWindow/UserInfoWindow.module.scss';
import styleSkillInfoWindow from './components/SkillInfoWindow/SkillInfoWindow.module.scss';
import type { UserSearchesPublicType, LocationType } from '../../../../flow';

type LocationPointType = {
  latitude?: number,
  longitude?: number,
};

type UserType = {
  id: string,
  location: LocationType,
  first_name: string,
  last_name: string,
  avatar: ?{
    id: string,
    smallThumbnail: string,
  },
  /* Frontend UI props */
  _showSkillTitle?: boolean,
};

type Props = {
  center: LocationPointType,
  zoom: number,
  onChange?: Function,
  userLocation?: { latitude: number, longitude: number },
  users?: Array<{ node: UserType }>,
  skills?: Array<{
    node: {
      id: string,
      title: string,
      price?: ?string,
      user: UserType,
      /* Frontend UI props */
      _showSkillTitle?: boolean,
    },
  }>,
  userSearchesPublic?: Array<{
    node: UserSearchesPublicType,
  }>,
  miniItems?: Array<{
    node: {
      id: string,
      centroid: LocationPointType,
      user_id?: string,
      skill_id?: string,
    },
  }>,
  onClickCluster?: ({ cluster: Object }) => any,
  markerClassName?: ?string,
  withLegend?: boolean,
  withMarkerPopUp?: boolean,
  withTransparentPins?: boolean,
  preferSkillMarker?: boolean,
  nonInteractive?: ?boolean,
  className?: ?string,
  withLocateMeBtn?: boolean,
  disableFullscreen?: boolean,
  renderSiblings?: Function,
  height?: string,
  width?: string,
  /**  Marker clickable identifier */
  clickable?: boolean,
};

/**
 * 1) Inverse lover latitude to upper,
 * so that pins with lower latitude values lie
 * on top of those with higher latitude values.
 */
const COORDINATE_TAIL = 7;
const _getZIndexFromLatitude = (latitude: number): number =>
  Math.floor(
    // eslint-disable-next-line no-restricted-properties
    10 ** (COORDINATE_TAIL + 2) - latitude * Math.pow(10, COORDINATE_TAIL),
  );

const _renderSkillsMarker = (node, { userLocation }) => {
  const { user, id, price, _showSkillTitle } = node;
  const { location } = user || {};
  return (
    location && (
      <MapMarker
        target="_blank"
        key={`PluralMap_skillMarker_${id}`}
        lat={location.latitude}
        lng={location.longitude}
        avatar={user.avatar}
        user={user}
        price={`$${price}`}
        zIndex={_getZIndexFromLatitude(location.latitude)}
        zIndexHover={10 ** 9}
        to={`/skills/${id}`}
        skill={node}
        InfoModalComponent={SkillInfoWindow}
        infoModalProps={{
          userLocation,
          className: styleSkillInfoWindow['skill-info-modal'],
          arrowClassName: styleSkillInfoWindow['info-modal-arrow'],
          skill: node,
        }}
        onClick={() =>
          mixpanelHelpers.trackEvent(
            analyticsHelpers.events.SEARCH_MAPVIEW_SKILLPIN.name,
            {
              'Other Skill ID': `${id}`,
            },
          )
        }
        showSkillTitle={_showSkillTitle}
      />
    )
  );
};

const _renderUsersMarkers = (
  users,
  {
    userLocation,
    markerClassName,
    withTransparentPins,
    preferSkillMarker,
    withMarkerPopUp,
    clickable,
    ...rest
  },
) =>
  users.map(({ node }) => {
    const {
      id,
      location,
      avatar,
      username,
      _showSkillTitle,
      main_skill,
    } = node;

    if (preferSkillMarker && main_skill) {
      return _renderSkillsMarker(
        {
          _showSkillTitle,
          user: node,
          ...main_skill,
        },
        {
          userLocation,
        },
      );
    }
    return (
      location && (
        <MapMarker
          target="_blank"
          key={`PluralMap_userMarker_${id}`}
          lat={location.latitude}
          lng={location.longitude}
          avatar={avatar}
          user={node}
          zIndex={_getZIndexFromLatitude(location.latitude)}
          zIndexHover={10 ** 9}
          to={clickable ? `/${username}` : undefined}
          onClick={() =>
            mixpanelHelpers.trackEvent(
              analyticsHelpers.events.SEARCH_MAPVIEW_USERPIN.name,
              {
                'Other User ID': `${id}`,
              },
            )
          }
          transparent={withTransparentPins}
          withMarkerPopUp={withMarkerPopUp}
          InfoModalComponent={withMarkerPopUp && UserInfoWindow}
          infoModalProps={
            withMarkerPopUp && {
              userLocation,
              className: styleUserInfoWindow['user-info-modal'],
              arrowClassName: styleSkillInfoWindow['info-modal-arrow'],
              user: node,
            }
          }
          className={markerClassName}
          {...rest}
        />
      )
    );
  });

const _renderSkillsMarkers = (skills, { userLocation }) =>
  skills.map(({ node }) => _renderSkillsMarker(node, { userLocation }));

const _renderSearchedSkillMarkers = (
  userSearchesPublic,
  { onClick, ...rest } = {},
) =>
  userSearchesPublic.map(({ node }) => {
    const { id, tag, location, is_public, user } = node;
    const { title } = tag || {};

    return (
      <MapMarkerUserSearchPublic
        key={`PluralMap_userSearchPublic_${id}${location.latitude}${location.longitude}`}
        lat={location.latitude}
        lng={location.longitude}
        title={title}
        isPublic={is_public}
        user={user}
        zIndex={_getZIndexFromLatitude(location.latitude)}
        zIndexHover={10 ** 9}
        {...rest}
        onClick={mixpanelHelpers.trackEventAndPass({
          name: analyticsHelpers.events.SEARCH_MAPVIEW_USERSEARCHPUBLICPIN.name,
          properties: {
            ...(user ? { 'Other User ID': `${user.id}` } : {}),
            term: node.term,
            latitude: location.latitude,
            longitude: location.longitude,
          },
        })(onClick ? () => onClick({ userSearchPublic: node }) : undefined)}
      />
    );
  });

const _renderDotMarkers = (miniItems) =>
  miniItems.map(({ node }) => {
    const { id, location, skill_id, user_search_public_id } = node;
    const uiType = skill_id || user_search_public_id ? 'demand' : 'people';
    return (
      <MapMarkerDot
        key={`PluralMap_dotMarker_${id}${location.latitude}${location.longitude}`}
        lat={location.latitude}
        lng={location.longitude}
        uiType={uiType}
        zIndex={_getZIndexFromLatitude(location.latitude)}
        zIndexHover={10 ** 9}
      />
    );
  });

/**
 * PluralMap
 *
 * wraps 3rd party Map component with business logic
 */
const PluralMap = ({
  // Top Level used props
  users = [],
  skills = [],
  userSearchesPublic = [],
  miniItems = [],
  userLocation,
  onClickCluster,
  center,
  zoom = 12,
  onChange,
  markerClassName,
  /** If true, renders users as skill markers if a skill is available */
  preferSkillMarker,
  withLegend = false,
  withTransparentPins = false,
  withMarkerPopUp = true,
  nonInteractive,
  className,
  clickable = true,
  ...rest
}: Props) => {
  /** !!important: do not replace it one state variable.
   *  This need to not lose data while the animation of modal closing is executing,
   *  also selectedUserSearchPublic is needed into sub modals mutation */
  const [selectedUserSearchPublic, setSelectedUserSearchPublic] = useState(
    null,
  );
  const [isSearchPublicModalOpen, setIsSearchPublicModalOpen] = useState(false);

  return (
    <div className={className}>
      <MapboxMap
        center={center}
        zoom={zoom}
        onChange={onChange}
        userLocation={userLocation}
        withLocateMeBtn
        disableFullscreen
        {...rest}
        {...(nonInteractive
          ? {
              scrollZoom: false,
              doubleClickZoom: false,
              pitchEnabled: false,
              rotateEnabled: false,
              dragPan: false,
              dragRotate: false,
            }
          : {})}
      >
        {_renderUsersMarkers(users, {
          userLocation,
          markerClassName,
          withTransparentPins,
          preferSkillMarker,
          withMarkerPopUp,
          isApproximateWithBackground: rest?.isApproximate,
          clickable,
          ...rest,
        })}
        {_renderSkillsMarkers(skills, {
          userLocation,
        })}
        {_renderSearchedSkillMarkers(userSearchesPublic, {
          onClick: ({ userSearchPublic }) => {
            setIsSearchPublicModalOpen(true);
            setSelectedUserSearchPublic(userSearchPublic);
          },
        })}
        {_renderDotMarkers(miniItems)}
      </MapboxMap>
      <ModalSearchedSkill
        isOpen={isSearchPublicModalOpen}
        toggle={() => {
          setIsSearchPublicModalOpen(false);
        }}
        userSearchPublic={selectedUserSearchPublic}
      />
      {withLegend && <MapLegend />}
    </div>
  );
};

export default PluralMap;
