/* @flow */
import graphql from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';

import { commitMutation } from '../..';
import type { LocationType } from '../../../../flow';

const mutation = graphql`
  mutation CreateDealMutation($input: CreateDealInput!) {
    createDeal(input: $input) {
      dealEdge {
        node {
          id
          ...DealsItem_deal
          ...DealControls_deal
          amount
          viewer_pay_role
          skill {
            id
          }
          hiring_type
          service_duration
          skill_offer {
            id
          }
          service_duration
          service_description
          service_starts_at
          service_location_type
          service_location {
            id
            longitude
            latitude
            name
            formatted_address
            note
          }
          note
        }
      }
      skillEdge {
        node {
          id
          # Optimistic update for profile skills
          ...SkillCardMini_skill @arguments(isSkillCardPreview: true)
        }
      }
      error {
        message
        stripeError {
          type
        }
      }
    }
  }
`;

/**
 * CreateDeal
 *
 * Valid combinations of optional inputs
 * ```js
   // One of these is required to identify the user
   { to_id, email, phone }
   // One of these is required to identify the user
   { skill_id, skill_title }
   ```
 */
export default ({
  to_id,
  email,
  phone,
  skill_id,
  skill_title,
  amount,
  note,
  service_description,
  service_location_type,
  service_location,
  service_starts_at,
  service_ends_at,
  service_duration,
  hiring_type,
  skill_offer_id,
}: {
  to_id?: string,
  email?: string,
  phone?: string,
  skill_id?: string,
  skill_title?: string,
  amount: string,
  note?: string,
  service_description?: string,
  service_location_type?: string,
  service_location: LocationType,
  service_starts_at?: string,
  service_ends_at?: string,
  service_duration?: number,
  hiring_type?: string,
  skill_offer_id?: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        to_id,
        email,
        phone,
        skill_id,
        skill_title,
        amount: parseFloat(amount.toString()),
        note,
        service_description,
        service_location_type,
        service_starts_at,
        service_ends_at,
        service_location:
          service_location && pick(service_location, ['longitude', 'latitude']),
        service_duration,
        hiring_type,
        skill_offer_id,
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: 'client:root:viewer',
        connectionInfo: [
          {
            key: 'DealsMessenger_deals',
            rangeBehavior: 'prepend',
            filters: { otherUserId: to_id },
          },
        ],
        edgeName: 'dealEdge',
      },
      to_id
        ? {
            type: 'RANGE_ADD',
            parentID: to_id,
            connectionInfo: [
              {
                key: 'Profile_skills',
                rangeBehavior: 'append',
              },
            ],
            edgeName: 'skillEdge',
          }
        : null,
    ].filter(Boolean);

    commitMutation({
      mutation,
      variables,
      configs,

      onCompleted: resolve,
      onError: reject,
    });
  });
