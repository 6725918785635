/* @flow */

const getSkillLink = ({ id }: { id: string }): string => `/skills/${id}`;

const getEditSkillLink = (
  { id }: { id: string },
  tab: string = 'title',
): string => `/skills/edit/${id}/${tab}`;

const getProfileLink = ({
  username,
  user_id,
}: {
  username?: string,
  user_id?: string,
}): string => `/${username || user_id}`;

const getNewMessageLink = (messageText: string): string =>
  `/messages/t/new${messageText ? `?text=${messageText}` : ''}`;

const getThreadLink = (threadId: string): string => `/messages/t/${threadId}`;

const getPaymentLink = (paymentId: string): string => `/payments/${paymentId}`;

const getPayoutMethodLink = (): string =>
  '/settings/payments-payouts/seller-account';

const getRoomLink = (room_name: string): string => `/room/${room_name}`;

const getDealLink = (deal_id: string): string => `/deals/${deal_id}`;

export default {
  getSkillLink,
  getEditSkillLink,
  getNewMessageLink,
  getProfileLink,
  getThreadLink,
  getPayoutMethodLink,
  getPaymentLink,
  getRoomLink,
  getDealLink,
};
