/* @flow */
const replaceUnsupportedChars = (
  str: string,
  replacement?: string = '',
): string => str.replace(/\/\/+/g, '/').replace(/[^0-9/]/g, replacement);

const monthYear = (text: string) => {
  let newText = replaceUnsupportedChars(text).substring(0, 7);
  if (text.match(/^\d{3}$/)) {
    newText = `${`${newText.substring(0, 2)}/${newText.substring(2)}`}`;
  }
  return newText;
};

const monthDayYear = (text: string) => {
  let newText = replaceUnsupportedChars(text).substring(0, 10);
  if (text.match(/^\d{3}$/)) {
    newText = `${`${newText.substring(0, 2)}/${newText.substring(2)}`}`;
  } else if (newText.match(/^\d{2}\/\d{3}$/)) {
    newText = `${`${newText.substring(0, 2)}/${newText.substring(
      3,
      5,
    )}/${newText.substring(5)}`}`;
  }
  return newText;
};

const dayMonthYear = (text: string) => {
  let newText = replaceUnsupportedChars(text).substring(0, 10);
  if (text.match(/^\d{3}$/)) {
    newText = `${`${newText.substring(0, 2)}/${newText.substring(2)}`}`;
  } else if (newText.match(/^\d{2}\/\d{3}$/)) {
    newText = `${`${newText.substring(0, 2)}/${newText.substring(
      3,
      5,
    )}/${newText.substring(5)}`}`;
  }
  return newText;
};

export default {
  monthYear,
  monthDayYear,
  dayMonthYear,
};
