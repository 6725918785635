import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation ContactAISearchResultsMutation(
    $input: ContactAISearchResultsInput!
  ) {
    contactAISearchResults(input: $input) {
      error {
        message
      }
    }
  }
`;

interface ContactAISearchResultsInput {
  /** ID of the thread with the AI assistant. */
  thread_id: string;
  /** Skill IDs to contact on behalf of user. */
  skills_ids?: string[];
  /** External result IDs to contact on behalf of user */
  external_results_ids?: string[];
}

/**
 * Contact AI search results.
 * @param input - Input data.
 * @returns Promise.
 * @category Mutations
 */
const contactAISearchResults = ({
  thread_id,
  skills_ids,
  external_results_ids,
}: ContactAISearchResultsInput): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        thread_id,
        skills_ids,
        external_results_ids,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        contactAISearchResults: {
          error: null,
        },
      },
      onCompleted: resolve,
      onError: reject,
    });
  });

export default contactAISearchResults;
