import React from 'react';
import classNames from 'classnames';
import validator from 'validator';
import {
  pluralUrls,
  analyticsHelpers,
  authHelpers,
} from '@pluralcom/plural-js-utils';
import { ButtonFlat, Text } from '@pluralcom/blueprint';

import { useNavigate } from 'react-router-dom';
import { withRouter } from '../../../hocs/withRouter/withRouter';
import { logger, mixpanelHelpers } from '../../../utils';

import { Container } from '../components';

import styles from './VerifyCodeHelp.module.scss';

interface VerifyCodeHelpProps {
  /** Custom class name */
  className?: string;
  /** Email or phone number */
  emailOrPhone: string;
  /** Auth request id */
  auth_reqid: string;
  /** Resend code mutation */
  resendCodeMutation: Function;
  /** Resend code mutation name */
  resendCodeMutationName?: string;
  /** Footer button props */
  footerButtonProps?: {
    /** On click function */
    onClick?: Function;
  };
  /** Buttons props */
  buttonsProps?: {
    /** Resend button props */
    resend?: Object;
    /** Edit button props */
    edit: Object;
  };
  /** identifier to add vertical padding */
  withScreenSpacing?: boolean;
  /** Is verification */
  isVerification?: boolean;
}

const _resendCode = async ({
  navigate,
  emailOrPhone,
  auth_reqid,
  resendCodeMutation,
  resendCodeMutationName,
  footerButtonProps,
}: {
  /** Navigate method */
  navigate: Function;
  /** Email or phone number */
  emailOrPhone: string;
  /** Auth request id */
  auth_reqid: string;
  /** Resend code mutation */
  resendCodeMutation: Function;
  /** Resend code mutation name */
  resendCodeMutationName: string;
  /** Footer button props */
  footerButtonProps?: {
    /** On click function */
    onClick?: Function;
  };
}) => {
  const input = authHelpers.parseMainAuthInputField(emailOrPhone);

  // - Resend code mutation input
  // -- If email is masked then 'input' will be empty so Backend will parse email from token
  // -- For login token doesn't exist so we need to pass email
  const resendCodeMutationInput = !(input && Object.keys(input)?.length)
    ? {}
    : input;

  try {
    const {
      [resendCodeMutationName]: { profile, error: serverErrorRes },
    } = await resendCodeMutation({
      auth_reqid,
      ...resendCodeMutationInput,
    });
    const success = !serverErrorRes;
    if (success) {
      if (footerButtonProps?.onClick) {
        footerButtonProps.onClick();
      } else {
        navigate(
          `/verify-code/${profile && profile.name ? `u=${profile.name}` : ''}`,
        );
      }
    }
  } catch (err: any) {
    logger.error(err && err.message);
  }
};

const VerifyCodeHelp = ({
  className,
  footerButtonProps = {},
  buttonsProps = {
    edit: {
      to: '/login',
    },
  },
  emailOrPhone,
  auth_reqid,
  resendCodeMutation,
  resendCodeMutationName = 'requestLoginPin',
  withScreenSpacing,
  isVerification,
}: VerifyCodeHelpProps) => {
  const navigate = useNavigate();
  return (
    <Container
      data-testid="verify_code_help__container"
      className={classNames([styles.container, className])}
      withSpacing={withScreenSpacing}
    >
      <Text
        fontSizeType="t7"
        stickToBreakpoint="lg"
        className={styles.text}
        element="span"
      >
        {validator?.isEmail(emailOrPhone)
          ? "We've emailed you a code. If you can't find it, please check your spam folder or use one of options below. "
          : "We texted you a code. If you haven't received it, we can send it again."}
      </Text>
      <div
        className={classNames([styles.container, styles['container-content']])}
      >
        <ButtonFlat
          data-testid="verify_code_help__buttons_reset"
          className={styles.button}
          onClick={() =>
            _resendCode({
              navigate,
              footerButtonProps,
              emailOrPhone,
              resendCodeMutation,
              resendCodeMutationName,
              auth_reqid,
            })
          }
          onMouseDown={() =>
            mixpanelHelpers.trackEvent(
              analyticsHelpers.events.VERIFYCODEHELP_BUTTON_RESENDCODE.name,
            )
          }
          uiType="secondary"
          size="lg"
          {...buttonsProps.resend}
        >
          Resend Code
        </ButtonFlat>
        {isVerification ? null : (
          <ButtonFlat
            data-testid="verify_code_help__buttons_edit"
            className={styles.button}
            uiType="secondary"
            size="lg"
            onMouseDown={() =>
              mixpanelHelpers.trackEvent(
                analyticsHelpers.events.VERIFYCODEHELP_BUTTON_EDITEMAIL.name,
              )
            }
            {...buttonsProps.edit}
          >
            {`Edit ${
              validator?.isEmail(emailOrPhone) ? 'Email' : 'Mobile Number'
            }`}
          </ButtonFlat>
        )}
        <ButtonFlat
          data-testid="verify_code_help__buttons_help"
          className={styles.button}
          onMouseDown={() =>
            mixpanelHelpers.trackEvent(
              analyticsHelpers.events.VERIFYCODEHELP_BUTTON_CONTACTSUPPORT.name,
            )
          }
          uiType="secondary"
          size="lg"
          {...{
            href: pluralUrls.getHelpUrl(),
            target: '_blank',
          }}
        >
          Contact Support
        </ButtonFlat>
      </div>
    </Container>
  );
};

export { VerifyCodeHelp as PureVerifyCodeHelp };

export default withRouter(VerifyCodeHelp);
