import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation SendMessageToAIAssistantMutation(
    $input: SendMessageToAIAssistantInput!
  ) {
    sendMessageToAIAssistant(input: $input) {
      thread_id
      message
      skill_title
      skills(first: 5) {
        edges {
          node {
            id
            ...SkillCardMini_skill @arguments(isSkillCardLean: true)
          }
        }
      }
      external_results {
        id
        url
        title
        image_url
        avatar_url
        first_name
        last_name
        formatted_address
        location {
          id
          name
        }
      }
      location {
        id
        name
      }
      error {
        message
      }
    }
  }
`;

const sendMessageToAIAssistant = ({
  type,
  thread_id,
  message,
  location,
}: {
  /** type */
  type: string;
  /** thread_id with the ai assistant */
  thread_id?: string;
  /** message */
  message: string;
  /** location */
  location?: Object;
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        type,
        thread_id,
        message,
        location,
      },
    };

    commitMutation({
      mutation,
      variables,
      // no optimistic response on purpose
      onCompleted: resolve,
      onError: reject,
    });
  });

export default sendMessageToAIAssistant;
