/**
 * @generated SignedSource<<ee495f4d76cffafb0a27a58067572ab4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HiringTypeEnum = "custom" | "direct" | "flat_rate" | "full_time" | "hourly" | "intern" | "intro_call" | "part_time" | "video_call" | "%future added value";
export type SkillOfferDateSettingEnum = "seller_timeframe" | "set_on_create" | "%future added value";
export type SkillOfferServiceLocationTypeEnumType = "buyer" | "online" | "remote" | "seller" | "%future added value";
export type StripeCurrencyEnumType = "ARS" | "AUD" | "BGN" | "CAD" | "CZK" | "DKK" | "EGP" | "EUR" | "HUF" | "MXN" | "NZD" | "PLN" | "RON" | "SAR" | "SEK" | "USD" | "%future added value";
export type EditSkillOfferInput = {
  clientMutationId?: string | null;
  date_setting?: ReadonlyArray<SkillOfferDateSettingEnum | null> | null;
  description?: string | null;
  hiring_type: HiringTypeEnum;
  id: string;
  prices?: ReadonlyArray<SkillOfferPricesInput | null> | null;
  prices_ids_to_delete?: ReadonlyArray<string | null> | null;
  service_delivery_duration?: number | null;
  service_duration?: number | null;
  service_location?: LocationInput | null;
  service_location_type?: ReadonlyArray<SkillOfferServiceLocationTypeEnumType | null> | null;
  title?: string | null;
};
export type SkillOfferPricesInput = {
  currency?: StripeCurrencyEnumType | null;
  description?: string | null;
  id?: string | null;
  price: number;
};
export type LocationInput = {
  city?: string | null;
  country?: string | null;
  country_code?: string | null;
  is_anonymized?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
  note?: string | null;
  offset?: string | null;
  postal_code?: string | null;
  zoom_level?: number | null;
};
export type EditSkillOfferMutation$variables = {
  input: EditSkillOfferInput;
};
export type EditSkillOfferMutation$data = {
  readonly editSkillOffer: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly skill_offer: {
      readonly date_setting: ReadonlyArray<SkillOfferDateSettingEnum | null> | null;
      readonly description: string | null;
      readonly hiring_type: HiringTypeEnum;
      readonly id: string;
      readonly price: number | null;
      readonly service_delivery_duration: number | null;
      readonly service_duration: number | null;
      readonly service_location: {
        readonly formatted_address: string | null;
        readonly id: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly note: string | null;
      } | null;
      readonly service_location_type: ReadonlyArray<SkillOfferServiceLocationTypeEnumType | null> | null;
      readonly title: string | null;
    } | null;
  } | null;
};
export type EditSkillOfferMutation = {
  response: EditSkillOfferMutation$data;
  variables: EditSkillOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillOffer",
  "kind": "LinkedField",
  "name": "skill_offer",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "service_location_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "service_location",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formatted_address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hiring_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date_setting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "service_duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "service_delivery_duration",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSkillOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSkillOfferPayload",
        "kind": "LinkedField",
        "name": "editSkillOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSkillOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSkillOfferPayload",
        "kind": "LinkedField",
        "name": "editSkillOffer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31da83dccf7d02b65a061f571db5ed6a",
    "id": null,
    "metadata": {},
    "name": "EditSkillOfferMutation",
    "operationKind": "mutation",
    "text": "mutation EditSkillOfferMutation(\n  $input: EditSkillOfferInput!\n) {\n  editSkillOffer(input: $input) {\n    error {\n      message\n      id\n    }\n    skill_offer {\n      id\n      title\n      description\n      price\n      service_location_type\n      service_location {\n        id\n        latitude\n        longitude\n        name\n        formatted_address\n        note\n      }\n      hiring_type\n      date_setting\n      service_duration\n      service_delivery_duration\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f43bef5532e9857a2007d7e03902b1c";

export default node;
