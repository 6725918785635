/**
 * @generated SignedSource<<5c85775efb90df8ff3eb6f0d39c281ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PaymentMethodTypeEnum = "alipay" | "amazon_pay" | "au_becs_debit" | "bacs_debit" | "bancontact" | "card" | "cashapp" | "eps" | "fpx" | "giropay" | "ideal" | "link" | "p24" | "sepa_debit" | "us_bank_account" | "%future added value";
export type CompleteSetupIntentInput = {
  clientMutationId?: string | null;
  setupIntent: string;
};
export type CompleteSetupIntentMutation$variables = {
  input: CompleteSetupIntentInput;
};
export type CompleteSetupIntentMutation$data = {
  readonly completeSetupIntent: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly paymentMethodEdge: {
      readonly node: {
        readonly card: {
          readonly brand: string | null;
          readonly exp_date: string | null;
          readonly id: string;
          readonly last4: string | null;
        } | null;
        readonly cashapp: {
          readonly cashtag: string | null;
          readonly id: string;
        } | null;
        readonly created_at: any | null;
        readonly id: string;
        readonly link: {
          readonly email: string | null;
          readonly id: string;
        } | null;
        readonly sepa_debit: {
          readonly id: string;
          readonly last4: string | null;
        } | null;
        readonly type: PaymentMethodTypeEnum | null;
        readonly us_bank_account: {
          readonly bank_name: string | null;
          readonly id: string;
          readonly last4: string | null;
        } | null;
      } | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type CompleteSetupIntentMutation = {
  response: CompleteSetupIntentMutation$data;
  variables: CompleteSetupIntentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last4",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethodEdge",
  "kind": "LinkedField",
  "name": "paymentMethodEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Card",
          "kind": "LinkedField",
          "name": "card",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "exp_date",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brand",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentMethodCashApp",
          "kind": "LinkedField",
          "name": "cashapp",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cashtag",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentMethodLink",
          "kind": "LinkedField",
          "name": "link",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentMethodUsBankAccount",
          "kind": "LinkedField",
          "name": "us_bank_account",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bank_name",
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentMethodSepaDebit",
          "kind": "LinkedField",
          "name": "sepa_debit",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteSetupIntentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteSetupIntentPayload",
        "kind": "LinkedField",
        "name": "completeSetupIntent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteSetupIntentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteSetupIntentPayload",
        "kind": "LinkedField",
        "name": "completeSetupIntent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ac25fd425b4ea55bbf15ed1b7f01c56",
    "id": null,
    "metadata": {},
    "name": "CompleteSetupIntentMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteSetupIntentMutation(\n  $input: CompleteSetupIntentInput!\n) {\n  completeSetupIntent(input: $input) {\n    success\n    paymentMethodEdge {\n      node {\n        id\n        type\n        card {\n          id\n          exp_date\n          last4\n          brand\n        }\n        cashapp {\n          id\n          cashtag\n        }\n        link {\n          id\n          email\n        }\n        us_bank_account {\n          id\n          bank_name\n          last4\n        }\n        sepa_debit {\n          id\n          last4\n        }\n        created_at\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f05a583a540d072f488e17d3ab51f76";

export default node;
