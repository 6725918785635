import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

// Query
const mutation = graphql`
  mutation UpdateSellerAccountMutation($input: UpdateSellerAccountInput!) {
    updateSellerAccount(input: $input) {
      error {
        message
      }
      seller_account {
        id
        requirements {
          id
          currently_due
          disabled_reason
          disabled_reason_text
          eventually_due
          past_due
        }
        ...SellerAccountBasicInfoForm_sellerAccount
        ...SellerAccountCompanyForm_sellerAccount
        ...SellerAccountPersonForm_sellerAccount
        ...SellerAccountPayoutMethodForm_sellerAccount
      }
      seller_account_edge {
        node {
          id
          requirements {
            id
            currently_due
            disabled_reason
            disabled_reason_text
            eventually_due
            past_due
          }
          ...SellerAccountBasicInfoForm_sellerAccount
          ...SellerAccountCompanyForm_sellerAccount
          ...SellerAccountPersonForm_sellerAccount
          ...SellerAccountPayoutMethodForm_sellerAccount
        }
      }
      user {
        id
        settings {
          id
          has_valid_payout_method
        }
      }
    }
  }
`;

interface UpdateSellerAccountInput {
  /** seller account id */
  stripe_account_id?: string;
  /** seller account token */
  stripe_account_token?: string;
  /** seller person id */
  stripe_person_id?: string;
  /** seller person token */
  stripe_person_token?: string;
  /** business_type i.e individual or company */
  business_type?: string;
  /** country i.e US */
  country?: string;
  /** if true, user accepts stripe terms of service AGAIN. (only applicable in case of editing). */
  accept_tos?: boolean;
}

export default (
  {
    stripe_account_id,
    stripe_account_token,
    stripe_person_id,
    stripe_person_token,
    business_type,
    country,
    accept_tos,
  }: UpdateSellerAccountInput,
  {
    parentID,
  }: {
    /** parent node ID */
    parentID?: string;
  } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    // Variables for query
    const variables = {
      input: {
        stripe_account_id,
        stripe_account_token,
        stripe_person_id,
        stripe_person_token,
        business_type,
        country,
        accept_tos,
      },
    };

    // config
    const configs = [
      {
        type: 'RANGE_ADD',
        parentID,
        connectionInfo: [
          {
            key: 'SellerAccountForm_sellerAccounts_accounts',
            rangeBehavior: 'prepend',
          },
          {
            key: 'PayoutMethods_sellerAccounts_accounts',
            rangeBehavior: 'prepend',
          },
          {
            key: 'PayoutMethodSection_sellerAccounts_accounts',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'seller_account_edge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
