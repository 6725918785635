/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

import type { CompleteSetupIntentMutation$data } from './__generated__/CompleteSetupIntentMutation.graphql';

const mutation = graphql`
  mutation CompleteSetupIntentMutation($input: CompleteSetupIntentInput!) {
    completeSetupIntent(input: $input) {
      success
      paymentMethodEdge {
        node {
          id
          type
          card {
            id
            exp_date
            last4
            brand
          }
          cashapp {
            id
            cashtag
          }
          link {
            id
            email
          }
          us_bank_account {
            id
            bank_name
            last4
          }
          sepa_debit {
            id
            last4
          }
          created_at
        }
      }
      error {
        message
      }
    }
  }
`;

const completeSetupIntent = (
  {
    setupIntent,
  }: {
    /** Setup intent */
    setupIntent: string,
  },
  paymentMethodsParentId: string,
): Promise<CompleteSetupIntentMutation$data> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        setupIntent,
      },
    };
    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: paymentMethodsParentId,
        connectionInfo: [
          {
            key: 'PaymentMethods_paymentMethods',
            /** To avoid duplicates being shown for a brief period due to updater config */
            /** @todo Fix duplicate issue when append is used */
            rangeBehavior: 'ignore',
          },
        ],
        edgeName: 'paymentMethodEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default completeSetupIntent;
