import React, { useCallback, useEffect, useMemo, useState } from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { createPaginationContainer } from 'react-relay';
import { LoadingLayout, FlatList, Text } from '@pluralcom/blueprint';
import classNames from 'classnames';
import { COLOR_NEUTRAL_950 } from '@pluralcom/blueprint/dist/styles/js/index';

import type { InputUsersList_search$data } from './__generated__/InputUsersList_search.graphql';

import InputUsersListItem from './components/InputUsersListItem/InputUsersListItem';

import styles from './InputUsersList.module.scss';

interface InputUsersListProps {
  /** relay Object */
  relay?: {
    /** hasMore */
    hasMore: Function;
    /** isLoading */
    isLoading: Function;
    /** loadMore */
    loadMore: Function;
    /** refetchConnection */
    refetchConnection: Function;
  };
  /** search data */
  search: InputUsersList_search$data;
  /** onClickItem */
  onClickItem: Function;
  /** userLocation */
  userLocation?: {
    /** latitude */
    latitude: number;
    /** longitude */
    longitude: number;
  };
  /** show loader  */
  isLoading?: boolean;
  /** custom classname */
  className?: string;
  /** searchTerm */
  searchTerm?: string;
  /** lastNetworkSyncedTimestamp - used to refetchConnection */
  lastNetworkSyncedTimestamp?: number;
  /** ListHeaderComponent */
  ListHeaderComponent?: React.ComponentProps<
    typeof FlatList
  >['ListHeaderComponent'];
}

/**
 *
 * FIGMA v0.0.1
 *  InputUsersList to render a list of users
 */
const InputUsersList = ({
  search,
  relay,
  onClickItem,
  isLoading,
  className,
  lastNetworkSyncedTimestamp,
  ListHeaderComponent,
  ...rest
}: InputUsersListProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [
    currLastNetworkSyncedTimestamp,
    setCurrLastNetworkSyncedTimestamp,
  ] = useState(lastNetworkSyncedTimestamp);
  /** data */
  const data = useMemo(() => search?.unifiedNetwork?.edges, [search]);
  /** load more */
  const loadMore = useCallback(() => {
    if (relay?.hasMore?.() && !relay?.isLoading?.()) {
      setIsLoadingMore(true);
      relay?.loadMore?.(10, () => {
        setIsLoadingMore(false);
      });
    }
  }, [relay]);

  /**
   * useEffect to refetch data when lastNetworkSyncedTimestamp is greater than queryTimestamp
   */
  useEffect(() => {
    if (
      lastNetworkSyncedTimestamp &&
      (!currLastNetworkSyncedTimestamp ||
        lastNetworkSyncedTimestamp > currLastNetworkSyncedTimestamp)
    ) {
      relay?.refetchConnection(
        20,
        () => {
          setCurrLastNetworkSyncedTimestamp(lastNetworkSyncedTimestamp);
        },
        {
          searchTerm: rest.searchTerm,
          latitude: rest.userLocation?.latitude,
          longitude: rest.userLocation?.longitude,
        },
      );
    }
  }, [
    lastNetworkSyncedTimestamp,
    currLastNetworkSyncedTimestamp,
    relay,
    rest.searchTerm,
    rest.userLocation,
  ]);

  /** Renders a list item */
  const _renderListItem = useCallback(
    ({ index }) => {
      const item = data?.[index];
      return item?.node ? (
        <InputUsersListItem user={item.node} onClick={onClickItem} />
      ) : null;
    },
    [data, onClickItem],
  );
  // eslint-disable-next-line no-nested-ternary
  return isLoading ? (
    /** renders LoadingLayout when isLoading is true */
    <LoadingLayout
      fullHeight
      spinnerProps={{
        spinnerColor: COLOR_NEUTRAL_950,
        size: 'md',
      }}
    />
  ) : data && data?.length > 0 ? (
    <FlatList
      data={data as React.ComponentProps<typeof FlatList>['data']}
      renderItem={_renderListItem}
      itemSize={64}
      loadMoreThresholdCount={5}
      className={classNames([styles['list-container'], className])}
      loadMore={loadMore}
      ListFooterComponent={
        isLoadingMore ? (
          <LoadingLayout
            spinnerProps={{
              spinnerColor: COLOR_NEUTRAL_950,
              size: 'md',
            }}
          />
        ) : undefined
      }
      {...rest}
      ListHeaderComponent={ListHeaderComponent}
    />
  ) : (
    <div className={styles['empty-data-container']}>
      <Text fontSizeType="t7">No result found</Text>
    </div>
  );
};

export { InputUsersList as PureInputUsersList };

export default createPaginationContainer(
  InputUsersList,
  {
    search: graphql`
      fragment InputUsersList_search on Search
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
      ) {
        unifiedNetwork(first: $count, after: $cursor)
          @connection(
            key: "PeopleSelector_InputUsersList_unifiedNetwork"
            filters: ["searchTerm"]
          ) {
          totalCount
          edges {
            node {
              id
              ...InputUsersListItem_user
            }
          }
        }
      }
    `,
  },
  {
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
        searchTerm: props.searchTerm,
        latitude: props.userLocation?.latitude,
        longitude: props.userLocation?.longitude,
      };
    },
    query: graphql`
      query InputUsersListPaginationQuery(
        $count: Int!
        $cursor: String
        $searchTerm: String
        $latitude: Float
        $longitude: Float
      ) {
        viewer {
          search(
            term: $searchTerm
            longitude: $longitude
            latitude: $latitude
          ) {
            ...InputUsersList_search @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `,
  },
);
