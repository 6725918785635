/* @flow */
import { useEffect } from 'react';

import startsWithAnyOf from '../../../../utils/startsWithAnyOf';

// @todo find a better way
const disabledOnRoutes = ['/search'];

/** List of disabled patterns
 *  Pattern is an object consisting of fields
 *  route -> url pattern to be matched with current location pathname
 *  state -> value to be matched if the location state contains key for it and value for state key is not false
 */
const disabledPatterns = [
  /** Skill offer modal */
  { route: '/skills/\\w+/offers/\\w+' },
  /** Skill details, state will have offer key if redirected after closing skill offer modal */
  { route: 'skills/\\w+', state: 'offer' },
]
  /** Convert route to regex once */
  .map(({ route, state }) => ({ route: new RegExp(route), state }));

/** Method to match the disabled route patterns
 *  to determine whether to perform a scroll to top
 *  transition or not
 */
const patternMatch = (pathname, state) =>
  disabledPatterns
    /** Iterate over all patterns */
    .map(({ route, state: patternState }) => {
      /** Check location pathname with regex */
      const match = pathname.match(route);
      /** If match is found & state values exists, return match found */
      if (match && state && patternState && state[patternState]) {
        return true;
      }
      /** Otherwise, return result based on whether match found or not */
      return match !== null;
    })
    /** Filter to extract only results which were true and return length equal to 0 or not */
    .filter((result) => result).length !== 0;

/** Method to check for whether to disable scroll to top transition */
const disabledRouteMatch = (location) => {
  /** Extract pathname and state from location */
  const { pathname, state } = location;
  /** Perform pattern matching */
  const patternMatchResult = patternMatch(pathname, state);
  /** If no pattern found, check for string starts with disabled list */
  if (!patternMatchResult) {
    return startsWithAnyOf(location.pathname, disabledOnRoutes);
  }
  /** Return true since pattern match was found */
  return true;
};

const useScrollToTop = ({ location, smooth }) => {
  /** Scroll to top on route change */
  useEffect(() => {
    if (!disabledRouteMatch(location)) {
      window.scrollTo({
        behavior: smooth ? 'smooth' : 'auto',
        left: 0,
        top: 0,
      });
    }
  }, [location, smooth]);
};

export default useScrollToTop;
