import React, { useState } from 'react';
import clamp from 'lodash/clamp';
import classNames from 'classnames';

import {
  Icon,
  ModalDialog,
  Text,
  TouchableOpacity,
} from '@pluralcom/blueprint';
import plPaymentHelpers from '@pluralcom/plural-js-utils/lib/plPaymentHelpers/plPaymentHelpers';

import styles from './PaymentTable.module.scss';

interface PaymentTableProps {
  /** class name */
  className?: string;
  /** amount */
  amount?: number;
  /**
   * if true, we set `viewer_pay_role: BUYER`
   * if false, we set `viewer_pay_role: SELLER`
   */
  isPayment?: boolean;
  /** currency e.g USD */
  currency?: string;
  /** fee or service fee, value should be between 0-1
   * 0.01 means 1% fee
   * 0.1 means 10% fee
   * 1 means 100% fee
   */
  fee?: number;
}

/**
 * - Figma v0.0.1
 * - PaymentTable component
 */
const PaymentTable = ({
  className,
  amount: inAmount,
  currency = 'USD',
  fee = 0.01,
  isPayment,
}: PaymentTableProps) => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const payment = {
    amount: inAmount || 0,
    from_currency: currency || 'USD',
    // Make sure fee is between 0-1
    service_fee: clamp(fee, 0, 1),
    viewer_pay_role: isPayment
      ? plPaymentHelpers.BUYER
      : plPaymentHelpers.SELLER,
  };

  // This is the amount (Payment or Payout) before service fee
  const amount = plPaymentHelpers.getAmountItemValue(payment);
  // This is the service fee (Payment or Payout)
  const serviceFee = plPaymentHelpers.getFeeItemValue(payment);
  // This is will be the total amount (Payment or Payout)
  const total = plPaymentHelpers.getTotalItemValue(payment);

  return (
    <>
      <div className={classNames([styles.container, className])}>
        <div className={styles.item}>
          <Text fontSizeType="t6">Amount</Text>
          <Text fontSizeType="t7">{inAmount ? `${amount}` : '-'}</Text>
        </div>
        <div className={styles.item}>
          <div className={styles['item-with-help-button']}>
            <Text fontSizeType="t6">Service Fee</Text>

            <TouchableOpacity
              className={styles['help-button']}
              onClick={() => setIsHelpModalOpen(true)}
            >
              <Icon
                icon={['far', 'question-circle']}
                type="fa"
                iconClassName={styles['help-button-icon']}
              />
            </TouchableOpacity>
          </div>
          <Text fontSizeType="t7">{inAmount ? `${serviceFee}` : '-'}</Text>
        </div>
        <div className={styles.item}>
          <Text fontSizeType="t6">
            Total {isPayment ? 'Payment' : 'Payout'}
          </Text>
          <Text fontSizeType="t7">{inAmount ? `${total}` : '-'}</Text>
        </div>
      </div>

      <ModalDialog
        title="Service Fee"
        bodyText="This service fee helps us operate Plural and cover the costs of processing payments. It's usually between 1-5% depending on the payment amount."
        bottomBarProps={{
          buttonCount: 1,
          primaryButtonProps: {
            text: 'Got it !',
            onClick: () => setIsHelpModalOpen(!isHelpModalOpen),
          },
        }}
        isOpen={isHelpModalOpen}
        toggle={() => setIsHelpModalOpen(!isHelpModalOpen)}
      />
    </>
  );
};

export default PaymentTable;
