/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {
  generateOptimisticId,
  objectHelpers,
} from '@pluralcom/plural-js-utils';
import { commitMutation } from '../..';

const EditSkillOfferMutation = ({
  id,
  title,
  description,
  price,
  service_location_type,
  hiring_type,
  date_setting,
  service_duration,
  service_location,
  service_delivery_duration,
}: {
  id: string,
  title: string,
  description: string,
  price: number,
  service_location_type: Array<'buyer', 'seller', 'remote', 'online'>,
  hiring_type: string,
  date_setting: Array<'set_on_create', 'seller_timeframe'>,
  service_duration: number,
  service_delivery_duration: number,
}): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation EditSkillOfferMutation($input: EditSkillOfferInput!) {
        editSkillOffer(input: $input) {
          error {
            message
          }
          skill_offer {
            id
            title
            description
            price
            service_location_type
            service_location {
              id
              latitude
              longitude
              name
              formatted_address
              note
            }
            hiring_type
            date_setting
            service_duration
            service_delivery_duration
          }
        }
      }
    `;

    const service_locationVariable = service_location_type?.includes('seller')
      ? pick(service_location, ['longitude', 'latitude'])
      : undefined;
    const variables = {
      input: objectHelpers.omitUndefined({
        id,
        title,
        description,
        price: price ? parseFloat(price) : undefined,
        service_location_type,
        hiring_type,
        date_setting,
        service_location: service_locationVariable?.latitude
          ? service_locationVariable
          : undefined,
        service_duration,
        service_delivery_duration,
      }),
    };

    const optimisticResponse = {
      editSkillOffer: {
        skill_offer: {
          node: {
            id,
            title,
            description,
            price: price ? parseFloat(price) : '',
            service_location_type,
            service_location,
            hiring_type,
            date_setting: date_setting || null,
            service_duration,
            service_delivery_duration,
          },
        },
      },
    };

    return commitMutation({
      mutation,
      optimisticResponse,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default EditSkillOfferMutation;
